import { extendWithAuthorization, http, defaultHttp } from '@/http'
import JOBS from '@/mock/jobs.json'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { reactive, readonly, toRefs } from 'vue'

const db = firebase.firestore()

const state = reactive({
  demands: {},
  authorizations: {},
  usersInfos: {}
})

export default function() {
  const getDemands = async application => {
    if (application && !state.demands[application]) {
      const demands = await db
        .collection('AUTHORIZATIONS/DATA/DEMANDS')
        .where('app', '==', application)
        .get()
        .then(querySnap => querySnap.docs.map(doc => doc.data()))

      await fetchUsersInfos(demands.map(d => d.email))

      state.demands[application] = demands.map(demand => ({ ...demand, ...state.usersInfos[demand.email] }))
    }
    return state.demands[application]
  }

  const deleteDemand = async(email, app) => {
    await db.doc(`AUTHORIZATIONS/DATA/DEMANDS/${email}:${app}`).delete()
    state.demands[app] = state.demands[app].filter(d => d.email !== email || d.app !== app)
  }

  const resetAuthorizations = () => {
    state.authorizations = {}
  }

  const fetchUsersInfos = async emails => {
    const filteredEmails = emails.filter(email => !state.usersInfos[email])

    filteredEmails.forEach(
      mail => (state.usersInfos[mail] = { dummy: true, mail, displayName: 'Chargement du nom...', uid: 'Chargement du matricule...' })
    )

    const extendedHttp = await extendWithAuthorization(http)
    const promises = []
    for (let i = 0; i < filteredEmails.length; i += 25) {
      promises.push(
        extendedHttp
          .get(`user?filter=mail&values=${filteredEmails.slice(i, i + 25).join()}`, {
            retry: {
              limit: 4,
              methods: ['get'],
              statusCodes: [500]
            }
          })
          .json()
      )
    }
    const infos = (await Promise.all(promises)).flat()
    infos.forEach(user => (state.usersInfos[user.mail] = user))
    filteredEmails.forEach(mail => {
      if (state.usersInfos[mail]?.dummy) delete state.usersInfos[mail]
    })

    return emails.map(e => state.usersInfos[e])
  }

  const fetchRolesUsers = async(application, role) => {
    if (application && !state.authorizations[application]?.[role]) {
      const users = await db
        .collection('AUTHORIZATIONS/DATA/USERS')
        .where(`roles.${application}`, 'array-contains', role)
        .get()
        .then(docs => docs.docs.map(doc => doc.id))

      fetchUsersInfos(users)
      if (!state.authorizations[application]) state.authorizations[application] = {}
      state.authorizations[application][role] = users
      const groups = await db
        .collection('AUTHORIZATIONS/DATA/GROUPS')
        .where(`roles.${application}`, 'array-contains', role)
        .get()
        .then(docs => docs.docs.map(doc => `group:${doc.id}`))
      state.authorizations[application][role].unshift(...groups)
    }
  }

  const getRolesByDocRef = docRef => {
    return docRef.get().then(snapshot => (snapshot.exists ? snapshot.data().roles : []))
  }

  const getUsersByRole = (application, role) =>
    (state.authorizations[application]?.[role] ?? []).map(u => {
      const getJobLabel = ({ id, label }) => `${id}|${label}`
      if (u.startsWith('group:')) {
        const id = u.split(':')[1]
        const job = JOBS.find(job => job.id === id)
        return { displayName: job ? getJobLabel(job) : id, uid: u, mail: u }
      } else {
        return state.usersInfos[u] ?? { displayName: 'INCONNU DU LDAP', mail: u }
      }
    })

  const getUserRoles = async({ mail, iirBusinessUnit, jobs }) => {
    let userRolesPromise,
      businessUnitsRolesPromise,
      everybodyRolesPromise,
      jobsRolesPromises = []
    if (mail.startsWith('group:')) {
      userRolesPromise = getRolesByDocRef(db.doc(`AUTHORIZATIONS/DATA/GROUPS/${mail.split(':')[1]}`))
    } else {
      userRolesPromise = getRolesByDocRef(db.doc(`AUTHORIZATIONS/DATA/USERS/${mail}`))
      businessUnitsRolesPromise = getRolesByDocRef(db.doc(`AUTHORIZATIONS/DATA/GROUPS/${iirBusinessUnit}`))
      jobsRolesPromises = jobs?.map(job => getRolesByDocRef(db.doc(`AUTHORIZATIONS/DATA/GROUPS/${job.shortId ?? job.id}`))) ?? []
      everybodyRolesPromise = getRolesByDocRef(db.doc(`AUTHORIZATIONS/DATA/GROUPS/EVERYBODY`))
    }
    let [userRoles, buRoles, everybodyRoles, ...jobsRoles] = await Promise.all([
      userRolesPromise,
      businessUnitsRolesPromise,
      everybodyRolesPromise,
      ...jobsRolesPromises
    ])

    jobsRoles = jobsRoles.reduce((acc, value) => {
      for (const app of Object.keys(value)) {
        acc[app] = [...new Set((acc[app] || []).concat(value[app]))]
      }
      return acc
    }, {})

    return { userRoles, buRoles, everybodyRoles, jobsRoles }
  }

  const deleteUserRole = async(email, appId, role) => {
    let roles
    if (email.startsWith('group:')) roles = await getRolesByDocRef(db.doc(`/AUTHORIZATIONS/DATA/GROUPS/${email.split(':')[1]}`))
    else roles = await getRolesByDocRef(db.doc(`AUTHORIZATIONS/DATA/USERS/${email}`))
    roles[appId] = roles[appId].filter(r => r !== role)
    state.authorizations[appId][role] = state.authorizations[appId][role].filter(mail => mail !== email)

    setUser(email, { roles })
  }

  const addUserRole = async(email, appId, role) => {
    const roles = await getRolesByDocRef(db.doc(`AUTHORIZATIONS/DATA/USERS/${email}`))
    roles[appId] ??= []
    if (roles[appId].includes(role)) return
    roles[appId].push(role)
    fetchUsersInfos([email])
    state.authorizations[appId][role] ??= []
    state.authorizations[appId][role].push(email)
    setUser(email, { roles }, true)
  }

  const deleteUser = async email => {
    if (email.startsWith('group:')) return
    await db.doc(`/AUTHORIZATIONS/DATA/USERS/${email}`).delete()
    Object.keys(state.authorizations).forEach(app => {
      Object.keys(state.authorizations[app]).forEach(role => {
        state.authorizations[app][role] = state.authorizations[app][role].filter(u => u !== email)
      })
    })
  }

  const setUser = (email, { roles, displayName, type }, merge = false) => {
    if (!email) return
    const filteredRoles = Object.fromEntries(Object.entries(roles).filter(entry => entry[1].length))
    if (email.startsWith('group:'))
      return db.doc(`/AUTHORIZATIONS/DATA/GROUPS/${email.split(':')[1]}`).set({ roles: filteredRoles, displayName, type }, { merge })
    else return db.doc(`/AUTHORIZATIONS/DATA/USERS/${email}`).set({ roles: filteredRoles }, { merge })
  }

  const copyUserRoles = async(sourceMail, targetMail, deleteSource = false, resetAuth = false) => {
    const roles = await getRolesByDocRef(db.doc(`AUTHORIZATIONS/DATA/USERS/${sourceMail}`))
    setUser(targetMail, { roles })

    if (deleteSource) {
      db.doc(`AUTHORIZATIONS/DATA/USERS/${sourceMail}`).delete()
    }
    
    if (resetAuth) {
      const extendedHttp = await extendWithAuthorization(defaultHttp)
      extendedHttp.get(`${process.env.VUE_APP_CF_DELETE_FIREBASE_USER}?email=${sourceMail}`)
    }
  }

  return {
    ...toRefs(readonly(state)),
    getDemands,
    deleteDemand,
    resetAuthorizations,
    fetchRolesUsers,
    fetchUsersInfos,
    getUsersByRole,
    getUserRoles,
    deleteUserRole,
    addUserRole,
    deleteUser,
    setUser,
    copyUserRoles
  }
}
