<template>
  <div class="search-user">
    <div class="search-input">
      <v-svg name="search" />
      <input
        v-model="search"
        :class="['main-input', { 'users-opened': users.length }]"
        type="text"
        placeholder="Rechercher par email, matricule, nom"
        @keypress.enter="enterPressed"
      />
      <div v-if="loading" class="loading" />
      <v-svg v-else-if="users.length" name="close" @click="search = ''" />
    </div>
    <div v-if="users.length" class="users">
      <div v-for="user in users" :key="user.mail" class="user" @click="selectUser(user)">{{ user.displayName }} ({{ user.mail }})</div>
    </div>
  </div>
</template>

<script>
  import { extendWithAuthorization, http } from '@/http'
  import { ref, watch } from 'vue'
  import useAuthorizationStore from '@/store/authorization.store'

  export default {
    name: 'SearchUser',
    emits: ['select'],
    props: {
      bulk: {
        type: Boolean,
        default: false
      }
    },

    setup(props, context) {
      const search = ref('')
      const users = ref([])
      const loading = ref(0)

      let debounceTimeout = null
      let controller = null

      watch(search, () => {
        controller && controller.abort()
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(searchUsers, 300)
      })

      const searchUsers = async () => {
        if (search.value.length < 4) {
          users.value = []
          return
        }
        controller = new AbortController()
        const signal = controller.signal
        const extendedHttp = await extendWithAuthorization(http)
        loading.value++
        extendedHttp
          .get('user/_search', { signal, searchParams: { mails: [search.value], uids: [search.value], displayNames: [search.value.toUpperCase()] } })
          .json()
          .then(r => (users.value = r))
          .catch(e => {
            if (e.name === 'AbortError') {
              // We know it's been canceled!
            } else console.error(e)
          })
          .finally(() => {
            loading.value--
          })
      }

      const selectUser = (user, clear = true) => {
        context.emit('select', user)
        if (clear) search.value = ''
      }

      const enterPressed = () => {
        if (props.bulk) {
          const authorizationStore = useAuthorizationStore()
          const mails = search.value.split(',').filter(v => /.+@.+\..+/.test(v))
          authorizationStore.fetchUsersInfos(mails)
          mails.forEach(mail => selectUser({ mail }, false))
          search.value = ''
        } else if (users.value.length === 1) selectUser(users.value[0])
      }

      return {
        search,
        users,
        loading,
        selectUser,
        enterPressed
      }
    }
  }
</script>

<style lang="scss" scoped>
  .search-user {
    position: relative;
    .search-input {
      display: flex;
      input {
        width: 100%;
        padding-left: 32px;
        &.users-opened {
          border-radius: 8px 8px 0 0;
        }
      }
      .svg-search {
        position: absolute;
        align-self: center;
        left: 6px;
      }
      .svg-close {
        position: absolute;
        top: 8px;
        right: 6px;
        cursor: pointer;
      }
      .loading {
        position: absolute;
        align-self: center;
        right: 8px;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        border-right: 2px solid black;
        animation: rotate 1s infinite;
      }
    }

    .users {
      box-shadow: var(--shadow-default);
      padding: 16px;
      border-radius: 0 0 8px 8px;
      position: absolute;
      background-color: var(--color-white);
      width: 100%;
      border: 1px solid rgba(1, 12, 20, 0.05);
      z-index: 1;

      .user {
        cursor: pointer;
        text-decoration: underline;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        &:hover {
          color: var(--color-second-accent);
        }
      }
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    60% {
      transform: rotate(720deg);
    }
    65% {
      transform: rotate(720deg);
    }
    100% {
      transform: rotate(0);
    }
  }
</style>
