import { globalProperties } from '@/main'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { computed, reactive, readonly, toRefs } from 'vue'
import { useRoute } from 'vue-router'

const db = firebase.firestore()

const state = reactive({
  _applications: [],
  selectedAppId: null
})

export default function() {
  const getAppById = id => state._applications.find(a => a.id === id)

  const selectedApp = computed(() => getAppById(state.selectedAppId))

  const setSelectedApp = id => {
    state.selectedAppId = id
  }

  const addApplication = async application => {
    await db.doc(`APPS/${application.id}`).set(application)
    fetchApplications(true)
  }

  const updateApplication = async application => {
    if (!application.id) return
    await db.doc(`APPS/${application.id}`).set(application)
    fetchApplications(true)
  }

  const deleteApplication = async({ id }) => {
    await db.doc(`APPS/${id}`).delete()
    fetchApplications(true)
  }

  const fetchApplications = (force = false) => {
    if (force || !state._applications.length)
      db.collection('APPS')
        .orderBy('id')
        .get()
        .then(querySnap => {
          state._applications = querySnap.docs.map(doc => doc.data())
          setSelectedApp(null)
        })
  }

  const applications = computed(() => {
    const route = useRoute()
    if (globalProperties.roles.global?.includes('super-admin') || globalProperties.roles.global?.includes('admin') || globalProperties.roles['smartboard-v2']?.includes('all-apps')) return state._applications
    if (route && globalProperties.roles['smartboard-v2']?.includes('responsible'))
      return state._applications.filter(app => app.responsibles?.find(r => r.email === globalProperties.user.mail)?.[route.name])

    return []
  })

  return {
    ...toRefs(readonly(state)),
    fetchApplications,
    selectedApp,
    setSelectedApp,
    addApplication,
    updateApplication,
    deleteApplication,
    applications,
    getAppById
  }
}
