import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  { path: '/', redirect: '/notifications' },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import(/* webpackChunkName: "Notifications" */ '../views/Notifications.vue')
  },
  {
    path: '/authorizations',
    name: 'Authorizations',
    component: () => import(/* webpackChunkName: "Authorizations" */ '../views/Authorizations.vue')
  },
  {
    path: '/polls',
    name: 'Polls',
    component: () => import(/* webpackChunkName: "Polls" */ '../views/Polls.vue')
  },
  {
    path: '/applications',
    name: 'Applications',
    component: () => import(/* webpackChunkName: "Applications" */ '../views/Applications.vue')
  },
  {
    path: '/caches',
    name: 'Caches',
    component: () => import(/* webpackChunkName: "Caches" */ '../views/Caches.vue')
  },
  {
    path: '/tools',
    name: 'Tools',
    component: () => import(/* webpackChunkName: "Tools" */ '../views/Tools.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
