<template>
  <div class="forbidden-view">
    <div class="message-container">
      <div class="title">Vous n’avez pas accès aux {{ viewName }}</div>
      <div class="message">Vous n’avez pas les droits pour consulter ou créer des {{ viewName }} </div>
    </div>
  </div>
</template>

<script>
  import { computed } from 'vue'
  import { useRoute } from 'vue-router'
  import routes from '@/helpers/routes'

  export default {
    name: 'ForbiddenView',
    setup() {
      const route = useRoute()
      const viewName = computed(() => routes.find(r => route.name === r.name)?.label?.toLowerCase())

      return { viewName }
    }
  }
</script>

<style lang="scss" scoped>
  .forbidden-view {
    display: flex;
    justify-content: center;
    padding: 44px;
    .message-container {
      padding: 40px 70px;
      background: white;
      box-shadow: var(--shadow-default);
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        @extend %font-title-5;
      }
      .message {
        color: hsla(var(--hsla), 0.7);
      }
    }
  }
</style>
