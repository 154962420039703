<template>
  <div ref="el" class="search-job">
    <span class="title">Sélectionner une fonction</span>
    <div class="search-input">
      <input
        ref="searchInput"
        v-model="search"
        :class="['main-input', { 'list-opened': opened }]"
        type="text"
        @keypress.enter="enterPressed"
        @click="opened = true">
      <v-svg name="search" />
    </div>
    <div v-if="opened" class="list">
      <div v-for="job in list" :key="job.id" class="job" @click="selectJob(job)">{{ job.id }}|{{ job.label }}</div>
    </div>
  </div>
</template>

<script>
  import { computed, ref, watch } from 'vue'
  import JOBS from '@/mock/jobs.json'

  export default {
    name: 'SearchJob',
    emits: ['select'],
    setup(_, context) {
      const search = ref('')

      const getDisplayName = ({ id, label }) => `${id}|${label}`

      const list = computed(() =>
        JOBS.filter(job =>
          getDisplayName(job)
            .toUpperCase()
            .includes(search.value.toUpperCase())
        ).slice(0, 10)
      )

      const selectJob = job => {
        context.emit('select', { displayName: getDisplayName(job), id: `group:${job.id}`, mail: `group:${job.id}`, type: 'JOB' })
        opened.value = false
      }

      const opened = ref(false)
      const el = ref(null)
      const clickOutside = e => {
        opened.value = !!el.value.contains(e.target)
      }
      watch(opened, () => {
        if (opened.value) window.addEventListener('click', clickOutside)
        else window.removeEventListener('click', clickOutside)
      })

      return {
        el,
        list,
        opened,
        search,
        selectJob
      }
    }
  }
</script>

<style lang="scss" scoped>
  .search-job {
    position: relative;
    .title {
      font-weight: bold;
    }
    .search-input {
      display: flex;
      input {
        width: 100%;
        padding-left: 32px;
        &.list-opened {
          border-radius: 8px 8px 0 0;
        }
      }
      .svg-search {
        position: absolute;
        align-self: center;
        left: 6px;
      }
    }

    .list {
      box-shadow: var(--shadow-default);
      padding: 16px;
      border-radius: 0 0 8px 8px;
      position: absolute;
      background-color: var(--color-white);
      width: 100%;
      border: 1px solid rgba(1, 12, 20, 0.05);
      z-index: 1;

      .job {
        cursor: pointer;
        text-decoration: underline;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        &:hover {
          color: var(--color-second-accent);
        }
      }
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    60% {
      transform: rotate(720deg);
    }
    65% {
      transform: rotate(720deg);
    }
    100% {
      transform: rotate(0);
    }
  }
</style>
