<template>
  <div class="navbar">
    <img src="@/assets/logo.png" class="logo">
    <div class="nav">
      <template v-for="route in routes">
        <router-link v-if="isAdmin || !route.admin" :key="route.name" class="router-link" :to="{ name: route.name }">{{ route.label }}</router-link>
      </template>
    </div>
    <div class="env">
      {{ env }}
    </div>
  </div>
</template>

<script>
  import { computed, inject } from 'vue'
  import routes from '@/helpers/routes'

  export default {
    name: 'NavBar',
    setup() {
      const isAdmin = inject('isAdmin')

      const env = computed(() => process.env.VUE_APP_BUILD_ENV)

      return {
        isAdmin,
        routes,
        env
      }
    }
  }
</script>

<style lang="scss" scoped>
  .navbar {
    height: 3.25rem;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    .logo {
      position: absolute;
      height: 3.25rem;
    }
    .nav {
      margin: auto;
      display: flex;
      height: 100%;
      .router-link {
        align-items: center;
        display: flex;
        height: 100%;
        border-bottom: 3px solid transparent;
        &:not(:last-child) {
          margin-right: 4rem;
        }
        &:hover {
          border-color: hsla(var(--hsla-second-accent), 0.25);
        }
        &.router-link-active {
          @extend %font-body-bold;
          color: var(--color-second-accent);
          border-color: var(--color-second-accent);
        }
      }
    }
    .env {
      margin-right: 1rem;
    }
  }
</style>
