<template>
  <div class="selector-switch">
    <div :key="id" v-for="{ id, label } in values" :class="['element', { selected: id === modelValue }]" @click="$emit('update:modelValue', id)">
      {{ label }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SelectorSwitch',
    props: {
      values: {
        type: Array,
        required: true
      },
      modelValue: {
        type: String,
        default: null
      }
    },
    setup() {}
  }
</script>

<style lang="scss" scoped>
  .selector-switch {
    display: flex;
    border-radius: 8px;
    background: hsla(var(--hsla-black), 0.05);
    height: 40px;
    .element {
      border-radius: 6px;
      margin: 4px;
      display: flex;
      align-items: center;
      padding: 8px 12px;
      cursor: pointer;
      &.selected {
        @extend %font-body-bold;
        background: var(--color-white);
        color: var(--color-second-accent);
      }
      &:hover:not(.selected) {
        background: hsla(var(--hsla-white), 0.5);
        color: var(--color-second-accent);
      }
    }
  }
</style>
