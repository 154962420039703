<template>
  <div class="search-app">
    <span class="title">Rechercher une application</span>
    <div class="search-input">
      <input
        ref="searchInput"
        v-model="search"
        :class="['main-input', { 'apps-opened': apps.length }]"
        type="text"
        placeholder="Nom de l'application"
        @keypress.enter="enterPressed">
    </div>
    <div v-if="apps?.length" class="apps">
      <div v-for="app in apps" :key="app.id" class="app" @click="selectApp(app)">{{ app.name }}</div>
    </div>
  </div>
</template>

<script>
  import { computed, ref } from 'vue'
  import useApplicationStore from '@/store/application.store'

  export default {
    name: 'SearchApp',
    emits: ['select'],

    setup(_, context) {
      const applicationStore = useApplicationStore()
      const { applications } = applicationStore

      const search = ref('')

      const apps = computed(() => {
        if (!search.value.length) return []
        return applications.value.concat({id: 'global', name: 'Global'}).filter(app => app.name.toUpperCase().includes(search.value.toUpperCase()))
      })

      const selectApp = app => {
        search.value = ''
        context.emit('select', app)
      }

      return {
        search,
        apps,
        selectApp
      }
    }
  }
</script>

<style lang="scss" scoped>
  .search-app {
    position: relative;
    .title {
      font-weight: bold;
    }
    .search-input {
      display: flex;
      input {
        width: 100%;
        &.apps-opened {
          border-radius: 8px 8px 0 0;
        }

        &.app-selected {
          &::placeholder {
            color: black;
          }
        }
      }
      .svg-search {
        position: absolute;
        align-self: center;
        left: 6px;
      }
    }

    .apps {
      box-shadow: var(--shadow-default);
      padding: 16px;
      border-radius: 0 0 8px 8px;
      position: absolute;
      background-color: var(--color-white);
      width: 100%;
      border: 1px solid rgba(1, 12, 20, 0.05);
      z-index: 1;
      max-height: 250px;
      overflow-y: auto;

      .app {
        cursor: pointer;
        text-decoration: underline;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        &:hover {
          color: var(--color-second-accent);
        }
      }
    }
  }
</style>
