<template>
  <div :class="['v-checkbox', { checked: modelValue }]" @click="$emit('update:modelValue', !modelValue)">
    <v-svg name="checkmark" h="1.3rem" />
  </div>
</template>

<script>
  export default {
    name: 'VCheckbox',
    props: {
      modelValue: {
        type: Boolean,
        required: true
      }
    },
    emits: ['update:modelValue']
  }
</script>

<style lang="scss" scoped>
  .v-checkbox {
    background: var(--color-white);
    color: var(--color-white);
    border: 1px solid hsla(var(--hsla-black), 0.2);
    width: 20px;
    height: 20px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.checked {
      background: var(--color-second-accent);
      border-color: hsla(var(--hsla-black), 0.5);
    }
  }
</style>
