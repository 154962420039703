<template>
  <div id="nav">
    <nav-bar :hidden-views="hiddenViews" />
  </div>
  <forbidden-view v-if="forbidden" />
  <router-view v-else />
  <modal-refresh v-if="hasNewContent" @close="hasNewContent = false" @refresh="refreshApp" />
</template>

<script>
  import NavBar from '@/components/NavBar'
  import ForbiddenView from '@/views/ForbiddenView'
  import useApplicationStore from '@/store/application.store'
  import { useRoute } from 'vue-router'
  import routes from '@/helpers/routes'
  import { computed, provide, ref } from 'vue'
  import { globalProperties } from '@/main'

  export default {
    name: 'Smartboard',
    components: {
      NavBar,
      ForbiddenView
    },
    setup() {
      const applicationStore = useApplicationStore()
      applicationStore.fetchApplications()

      const roles = globalProperties.roles
      const route = useRoute()
      const adminViews = routes.filter(r => r.admin)

      provide('isAdmin', computed(() => roles.global?.includes('super-admin')))

      const forbidden = computed(() => {
        if (roles.global?.includes('super-admin')) return false
        else if (adminViews.map(r => r.name).includes(route.name)) return true
        else return !applicationStore.applications.value.length
      })

      const hiddenViews = computed(() => {
        return !roles.global?.includes('super-admin') ? adminViews : []
      })

      const hasNewContent = ref(false)
      const refreshing = ref(false)
      const newRegistration = ref(null)

      const showRefreshUI = e => {
        newRegistration.value = e.detail
        hasNewContent.value = true
      }

      const refreshApp = () => {
        hasNewContent.value = false
        newRegistration.value?.waiting.postMessage({ type: 'SKIP_WAITING' })
      }

      document.addEventListener('swUpdated', showRefreshUI, { once: true })
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          if (refreshing.value) return
          // eslint-disable-next-line no-console
          console.info('[Service worker] refreshing app')
          refreshing.value = true
          window.location.reload()
        })
      }

      return {
        hasNewContent,
        refreshApp,
        hiddenViews,
        forbidden
      }
    }
  }
</script>
