<template>
  <div class="backdrop" @click.self="$emit('close')">
    <div class="side-popup">
      <div class="content"> <slot /></div>
      <div class="footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SidePopup',
    emits: ['close']
  }
</script>

<style lang="scss" scoped>
  .backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: hsla(var(--hsla-black), 0.05);
    backdrop-filter: blur(8px);
    display: flex;
    justify-content: flex-end;
    .side-popup {
      background: var(--color-white);
      height: stretch;
      overflow-y: auto;
      position: relative;
      width: 600px;
      box-shadow: var(--shadow-default);
      .content {
        padding: 16px 16px 64px 16px;
      }
      .footer {
        width: 600px;
        position: fixed;
        bottom: 0;
        right: 0;
      }
    }
  }
</style>
