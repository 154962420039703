<template>
  <div v-tooltip.bottom="!hideTooltip && app ? app.name : null" class="app-logo">
    <v-svg v-if="!app" :name="icon" class="icon" />
    <img v-else-if="app.icon" :src="app.icon" class="icon">
    <div v-else class="icon">{{ imageRemplacement }}</div>
  </div>
</template>

<script>
  import { computed } from 'vue'
  import useApplicationStore from '@/store/application.store'
  export default {
    name: 'AppLogo',
    props: {
      application: {
        type: [Object, String],
        default: null
      },
      icon: {
        type: String,
        default: 'more'
      },
      hideTooltip: {
        type: Boolean,
        default: false
      }
    },
    setup(props) {
      const applicationStore = useApplicationStore()

      const app = computed(() => {
        if (typeof props.application === 'string') return applicationStore._applications.value.find(a => a.id === props.application)
        return props.application
      })

      const imageRemplacement = computed(() => {
        const words = app.value.name
          .toUpperCase()
          .split(' ')
          .slice(0, 2)
        return words.length === 2 ? words.map(word => word[0]).join('') : words[0].slice(0, 2)
      })

      return { imageRemplacement, app }
    }
  }
</script>

<style lang="scss" scoped>
  .app-logo {
    height: 2rem;
    width: 2rem;
    border-radius: 0.25rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    &.big {
      width: 2.75rem;
      height: 2.75rem;
    }
    &.bg-white {
      background: var(--color-white);
    }
    &.bg-grey {
      background: hsla(var(--hsla-black), 0.05);
    }
    .icon {
      @extend %font-title-6;
      color: var(--color-second-accent);
      max-width: 100%;
      max-height: 100%;
    }
  }
</style>
