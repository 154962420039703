<template>
  <div class="v-select">
    <select :value="modelValue" @input="$emit('update:modelValue', $event.target.value)">
      <option key="v" v-for="v in values" :value="v">{{ v }}</option>
    </select>
  </div>
</template>

<script>
  export default {
    name: 'VSelect',
    props: {
      values: {
        type: Array,
        required: true
      },
      modelValue: {
        type: String,
        default: null
      }
    },
    setup() {}
  }
</script>

<style lang="scss" scoped>
  .v-select {
    select {
      border: none;
      border-radius: 0.5rem;
      background: hsla(var(--hsla-black), 0.05);
      outline-style: none;
      height: 33px;
    }
  }
</style>
