<template>
  <div ref="el" class="search-bu">
    <span class="title">Sélectionner une BU</span>
    <div class="search-input">
      <input
        ref="searchInput"
        v-model="search"
        :class="['main-input', { 'list-opened': opened }]"
        type="text"
        @keypress.enter="enterPressed"
        @click="opened = true">
      <v-svg name="search" />
    </div>
    <div v-if="opened" class="list">
      <div v-for="bu in list" :key="bu.id" class="bu" @click="selectBu(bu)">{{ bu }}</div>
    </div>
  </div>
</template>

<script>
  import { computed, ref, watch } from 'vue'

  const BU_LIST = ['AUCHAN-HYPER', 'SUPER', 'AUCHAN-LUX','PINTAVEST', 'FRA224', 'FRA225', 'AVRILLE', 'FRA080', 'SCHIEVER']

  export default {
    name: 'SearchBu',
    emits: ['select'],
    setup(_, context) {
      const search = ref('')

      const list = computed(() => BU_LIST.filter(bu => bu.toUpperCase().includes(search.value.toUpperCase())))

      const selectBu = bu => {
        context.emit('select', { displayName: bu, uid: `group:${bu}`, mail: `group:${bu}`, type: 'BU' })
        opened.value = false
      }
      
      const opened = ref(false)
      const el = ref(null)
      const clickOutside = e => {
        opened.value = !!el.value.contains(e.target)
      }
      watch(opened, () => {
        if (opened.value) window.addEventListener('click', clickOutside)
        else window.removeEventListener('click', clickOutside)
      })

      return {
        el,
        list,
        opened,
        search,
        selectBu
      }
    }
  }
</script>

<style lang="scss" scoped>
  .search-bu {
    position: relative;
    .title {
      font-weight: bold;
    }
    .search-input {
      display: flex;
      input {
        width: 100%;
        padding-left: 32px;
        &.list-opened {
          border-radius: 8px 8px 0 0;
        }
      }
      .svg-search {
        position: absolute;
        align-self: center;
        left: 6px;
      }
    }

    .list {
      box-shadow: var(--shadow-default);
      padding: 16px;
      border-radius: 0 0 8px 8px;
      position: absolute;
      background-color: var(--color-white);
      width: 100%;
      border: 1px solid rgba(1, 12, 20, 0.05);
      z-index: 1;

      .bu {
        cursor: pointer;
        text-decoration: underline;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        &:hover {
          color: var(--color-second-accent);
        }
      }
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }
    60% {
      transform: rotate(720deg);
    }
    65% {
      transform: rotate(720deg);
    }
    100% {
      transform: rotate(0);
    }
  }
</style>
